<script setup>
const props = defineProps({ blok: Object });
const { noGradient } = useUtils();
const { successStories, link, show } = await useSuccessStories();

const images = [
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f0b_darshan-patel-QJEVpydulGs-unsplash.jpg",
    alt: "darshan-patel",
  },
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f09_cesar-rincon-XHVpWcr5grQ-unsplash.jpg",
    alt: "cesar-rincon",
  },
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f0a_vladislav-nikonov-mRelDTGo3HY-unsplash.jpg",
    alt: "vladislav-nikonov",
  },
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f07_christopher-campbell-rDEOVtE7vOs-unsplash.jpg",
    alt: "christopher-campbell",
  },
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f08_harps-joseph-tAvpDE7fXgY-unsplash.jpg",
    alt: "harps-joseph",
  },
  {
    src: "https://cdn.prod.website-files.com/66338e43cc46bc56f3f48e45/66338e43cc46bc56f3f48f06_gift-habeshaw-0nNOc6OAwLI-unsplash.jpg",
    alt: "gift-habeshaw",
  },
];
</script>

<template>
  <div v-editable="blok" class="features-bento-grid-3">
    <div class="content cta">
      <div
        class="feature-bento-box feature-bento-box-with-bg h-full"
        style="opacity: 1"
      >
        <div class="feature-bento-box-content">
          <div class="feature-bento-box-icon-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              app="ikonik"
              class="feature-bento-box-icon-white"
            >
              <defs app="ikonik">
                <clipPath app="ikonik">
                  <rect
                    width="24"
                    height="24"
                    fill="currentColor"
                    app="ikonik"
                    class="rect-648gl"
                  ></rect>
                </clipPath>
              </defs>
              <g clip-path="url(#clip0_201_56581)" app="ikonik">
                <path
                  d="M14.9316 16.3273L11.9155 14.3539L13.0768 12.2249C13.3569 11.6933 13.5036 11.1016 13.5044 10.5007V7.12573C13.5034 5.83209 12.9893 4.59165 12.0749 3.67655C11.1606 2.76145 9.92052 2.24642 8.62687 2.24445C5.94014 2.24445 3.75436 4.43421 3.75436 7.12573V10.5007C3.7515 11.1013 3.89663 11.6934 4.17694 12.2246L5.3445 14.3651L2.34595 16.3273C1.86221 16.6432 1.46501 17.0749 1.1904 17.5833C0.915794 18.0916 0.772471 18.6605 0.773438 19.2382L0.75 23.2507H16.5044V19.2426C16.5059 18.6641 16.3628 18.0944 16.0881 17.5853C15.8135 17.0761 15.4159 16.6437 14.9316 16.3273ZM15.0044 21.7507H2.25872L2.27325 19.2426C2.27212 18.9133 2.35331 18.5889 2.50945 18.2989C2.66559 18.009 2.89172 17.7626 3.1673 17.5823L7.32427 14.8622L5.49375 11.5063C5.33506 11.1949 5.25299 10.8502 5.25436 10.5007V7.12573C5.25436 6.23062 5.60994 5.37218 6.24287 4.73924C6.87581 4.10631 7.73425 3.75073 8.62936 3.75073C9.52447 3.75073 10.3829 4.10631 11.0158 4.73924C11.6488 5.37218 12.0044 6.23062 12.0044 7.12573V10.5007C12.0042 10.8506 11.9204 11.1954 11.7599 11.5063L9.93572 14.8507L14.1103 17.5823C14.3859 17.7627 14.612 18.009 14.7681 18.299C14.9243 18.589 15.0055 18.9133 15.0044 19.2427V21.7507Z"
                  fill="currentColor"
                  app="ikonik"
                  class="path-g6pg"
                ></path>
                <path
                  d="M21.6817 14.8273L18.6656 12.8538L19.8269 10.7249C20.107 10.1933 20.2537 9.60157 20.2545 9.0007V5.6257C20.2568 4.67672 19.9819 3.74772 19.4637 2.95276C18.9454 2.15781 18.2062 1.53152 17.337 1.15079C16.4677 0.770066 15.5062 0.651478 14.5705 0.809591C13.6347 0.967705 12.7656 1.39564 12.0697 2.04084L13.0891 3.14132C13.5713 2.69548 14.1731 2.40003 14.8208 2.2912C15.4684 2.18237 16.1337 2.26488 16.7352 2.52863C17.3366 2.79237 17.848 3.22588 18.2067 3.77601C18.5654 4.32614 18.7558 4.96897 18.7545 5.6257V9.0007C18.7543 9.35058 18.6705 9.69536 18.51 10.0063L16.6858 13.3507L20.8604 16.0823C21.136 16.2626 21.3621 16.509 21.5182 16.799C21.6744 17.0889 21.7556 17.4133 21.7545 17.7427V20.2507H18.3795V21.7507H23.2545V17.7426C23.256 17.1641 23.1129 16.5944 22.8382 16.0853C22.5635 15.5761 22.166 15.1437 21.6817 14.8273Z"
                  fill="currentColor"
                  app="ikonik"
                  class="path-tzpfu"
                ></path>
              </g>
            </svg>
          </div>
          <div class="text-left">
            <h2 class="feature-bento-box-title">
              {{ blok.title || "Stay connected" }}
            </h2>
            <div class="feature-bento-box-summary">
              {{
                blok.text ||
                "Easily integrate with all your favorite tools through"
              }}
            </div>
          </div>
          <MyLink v-bind="blok" :link-text="blok.link_text" button no-icon />
          <!-- <a href="/contact-v3" class="button w-button">Join the future</a> -->
        </div>
        <CommunityLoop :images />
      </div>
    </div>

    <div
      v-for="successStory in successStories.filter((_, i) => i < 3)"
      :key="successStory.slug"
      class="content feature-bento-box h-100 follow-box clickable"
      @click="show(successStory)"
    >
      <div class="feature-bento-box-content text-left">
        <div class="d-flex align-center w-100">
          <div class="mr-2">
            <NuxtImg
              v-if="successStory.content?.logo?.filename"
              :src="successStory.content?.logo?.filename"
              loading="lazy"
              height="22"
              alt=""
              class="white-logo ml-auto"
            />
          </div>
          <div v-if="successStory.quote?.content.portrait" class="ml-auto">
            <NuxtImg
              :src="successStory.quote.content.portrait.filename"
              :alt="
                successStory.quote.content.portrait.alt ||
                successStory.quote.content.author_full_name
              "
              width="40"
              loading="lazy"
              class="community-image"
            />
          </div>
        </div>
        <div class="h-100">
          <h2 class="feature-bento-box-title no-gradient">
            {{ noGradient(successStory.content.headline) }}
          </h2>
          <div class="feature-bento-box-summary">
            {{ successStory.content.teaser }}
          </div>
        </div>
        <div class="d-flex align-center w-100">
          <MyLink v-bind="link(successStory)" :button="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.community-loop {
  overflow: visible;
}
.cta {
  grid-area: 1 / 1 / 3 / 2;
}
.content:last-child {
  grid-area: 2 / 2 / 3 / 4;
}
.feature-bento-box-content {
  max-width: 100%;
}
.feature-bento-box.feature-bento-box-with-bg {
  min-height: 580px;
}
@media screen and (max-width: 950px) {
  .features-bento-grid-3 {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
